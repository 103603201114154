import SlideShowGallery_SlideShowTextBottomComponent from '@wix/thunderbolt-elements/src/components/NativeGalleries/SlideShowGallery/viewer/skinComps/SlideShowTextBottom/SlideShowTextBottom.skin';
import SlideShowGallery_SlideShowTextBottomController from '@wix/thunderbolt-elements/src/components/NativeGalleries/SlideShowGallery/viewer/SlideShowGallery.controller';


const SlideShowGallery_SlideShowTextBottom = {
  component: SlideShowGallery_SlideShowTextBottomComponent,
  controller: SlideShowGallery_SlideShowTextBottomController
};


export const components = {
  ['SlideShowGallery_SlideShowTextBottom']: SlideShowGallery_SlideShowTextBottom
};

